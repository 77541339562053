*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --font-title: "Montserrat";
  --font-body: "DM Sans";
  --color-brand: 313 100% 47%;
  --color-bg: 42 33% 94%;
  --color-bg-inverse: 229 38% 22%;
  --color-bg-surface: 0 0% 100%;
  --color-bg-surface-hover: 42 19% 95%;
  --color-bg-surface-selected: 208 40% 92%;
  --color-bg-surface-selected-hover: 208 40% 87%;
  --color-bg-surface-disabled: 42 33% 94%;
  --color-bg-surface-muted: 42 19% 90%;
  --color-bg-surface-brand: 313 100% 90%;
  --color-bg-surface-brand-hover: 313 100% 85%;
  --color-bg-surface-info: 220 80% 90%;
  --color-bg-surface-success: 137 70% 90%;
  --color-bg-surface-warning: 29 100% 90%;
  --color-bg-surface-critical: 346 80% 90%;
  --color-bg-muted: 42 17% 89%;
  --color-bg-muted-hover: 42 33% 94%;
  --color-bg-muted-selected: 42 17% 84%;
  --color-bg-brand: 313 100% 47%;
  --color-bg-brand-hover: 313 100% 37%;
  --color-bg-brand-selected: 313 100% 27%;
  --color-bg-primary: 229 38% 22%;
  --color-bg-primary-hover: 229 38% 32%;
  --color-bg-primary-selected: 229 38% 15%;
  --color-bg-secondary: 229 10% 83%;
  --color-bg-secondary-hover: 229 10% 93%;
  --color-bg-secondary-selected: 229 10% 78%;
  --color-bg-tertiary: 0 0% 100%;
  --color-bg-tertiary-hover: 229 10% 88%;
  --color-bg-tertiary-selected: 229 10% 78%;
  --color-bg-info: 220 70% 30%;
  --color-bg-info-hover: 220 70% 35%;
  --color-bg-info-selected: 220 70% 20%;
  --color-bg-success: 137 70% 30%;
  --color-bg-success-hover: 137 70% 35%;
  --color-bg-success-selected: 137 70% 20%;
  --color-bg-warning: 29 70% 40%;
  --color-bg-warning-hover: 29 70% 45%;
  --color-bg-warning-selected: 29 70% 30%;
  --color-bg-critical: 346 70% 30%;
  --color-bg-critical-hover: 346 70% 35%;
  --color-bg-critical-selected: 346 70% 20%;
  --color-text: 229 7% 24%;
  --color-text-primary: 229 38% 22%;
  --color-text-inverse: 0 0% 100%;
  --color-text-muted: 229 7% 42%;
  --color-text-link: 229 38% 22%;
  --color-text-link-hover: 229 45% 42%;
  --color-text-brand: 313 100% 57%;
  --color-stroke: 42 19% 85%;
  --color-stroke-secondary: 42 19% 75%;
  --color-stroke-disabled: 42 8% 83%;
  --color-stroke-selected: 208 40% 75%;
  --color-stroke-input: 224 7% 70%;
  --color-stroke-input-hover: 224 7% 60%;
  --color-stroke-input-active: 224 7% 50%;
  --color-stroke-input-disabled: 224 7% 85%;
  --color-stroke-brand: 313 100% 65%;
  --color-stroke-info: 220 35% 70%;
  --color-stroke-success: 137 35% 70%;
  --color-stroke-warning: 29 35% 70%;
  --color-stroke-critical: 346 35% 70%;
  --color-focus-ring: 229, 100%, 22%, .35;
  --radius-sm: .25rem;
  --radius: .5rem;
  --radius-lg: 1rem;
  --radius-xl: 1.5rem;
  --radius-2xl: 2rem;
}

html {
  scroll-behavior: smooth;
}

body {
  text-wrap: pretty;
  background-color: hsl(var(--color-bg));
  font-family: var(--font-body), ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  color: hsl(var(--color-text));
  font-feature-settings: "rlig" 1, "calt" 1;
  line-height: 20px;
}

* {
  border-color: hsl(var(--color-stroke));
}

h1, h2, h3, h4 {
  font-family: var(--font-title), ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: hsl(var(--color-text-primary));
  font-weight: 800;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 520px) {
  .container {
    max-width: 520px;
  }
}

@media (width >= 680px) {
  .container {
    max-width: 680px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-24 {
  margin-top: 6rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-auto {
  height: auto;
}

.h-screen {
  height: 100vh;
}

.w-10 {
  width: 2.5rem;
}

.w-40 {
  width: 10rem;
}

.w-6 {
  width: 1.5rem;
}

.w-full {
  width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-xs {
  max-width: 20rem;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.text-balance {
  text-wrap: balance;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-stroke-input {
  border-color: hsl(var(--color-stroke-input));
}

.bg-background {
  background-color: hsl(var(--color-bg));
}

.bg-background-inverse {
  background-color: hsl(var(--color-bg-inverse));
}

.bg-background-surface {
  background-color: hsl(var(--color-bg-surface));
}

.bg-brand {
  background-color: hsl(var(--color-brand));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-background {
  --tw-gradient-from: hsl(var(--color-bg)) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--color-bg) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[49\.99\%\] {
  --tw-gradient-from-position: 49.99%;
}

.to-\[\#d81476\] {
  --tw-gradient-to: #d81476 var(--tw-gradient-to-position);
}

.to-\[50\%\] {
  --tw-gradient-to-position: 50%;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-extralight {
  font-weight: 200;
}

.font-semibold {
  font-weight: 600;
}

.text-foreground {
  color: hsl(var(--color-text));
}

.text-foreground-brand {
  color: hsl(var(--color-text-brand));
}

.text-foreground-inverse {
  color: hsl(var(--color-text-inverse));
}

.text-foreground-link {
  color: hsl(var(--color-text-link));
}

.text-foreground-primary {
  color: hsl(var(--color-text-primary));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:bg-background-brand-hover:hover {
  background-color: hsl(var(--color-bg-brand-hover));
}

.hover\:bg-background-surface\/75:hover {
  background-color: hsl(var(--color-bg-surface) / .75);
}

.hover\:text-foreground-link-hover:hover {
  color: hsl(var(--color-text-link-hover));
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:bg-background-surface\/75:focus {
  background-color: hsl(var(--color-bg-surface) / .75);
}

@media (width >= 520px) {
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (width >= 1024px) {
  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .\32 xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .\32 xl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
}
/*# sourceMappingURL=index.178894d8.css.map */
