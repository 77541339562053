@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
	:root {
		--font-title: "Montserrat";
		--font-body: "DM Sans";

		--color-brand: 313 100% 47%;

		--color-bg: 42 33% 94%;
		--color-bg-inverse: 229 38% 22%;
		--color-bg-surface: 0 0% 100%;
		--color-bg-surface-hover: 42 19% 95%;
		--color-bg-surface-selected: 208 40% 92%;
		--color-bg-surface-selected-hover: 208 40% 87%;
		--color-bg-surface-disabled: 42 33% 94%;
		--color-bg-surface-muted: 42 19% 90%;
		--color-bg-surface-brand: 313 100% 90%;
		--color-bg-surface-brand-hover: 313 100% 85%;
		--color-bg-surface-info: 220 80% 90%;
		--color-bg-surface-success: 137 70% 90%;
		--color-bg-surface-warning: 29 100% 90%;
		--color-bg-surface-critical: 346 80% 90%;

		--color-bg-muted: 42 17% 89%;
		--color-bg-muted-hover: 42 33% 94%;
		--color-bg-muted-selected: 42 17% 84%;
		--color-bg-brand: 313 100% 47%;
		--color-bg-brand-hover: 313 100% 37%;
		--color-bg-brand-selected: 313 100% 27%;
		--color-bg-primary: 229 38% 22%;
		--color-bg-primary-hover: 229 38% 32%;
		--color-bg-primary-selected: 229 38% 15%;
		--color-bg-secondary: 229 10% 83%;
		--color-bg-secondary-hover: 229 10% 93%;
		--color-bg-secondary-selected: 229 10% 78%;
		--color-bg-tertiary: 0 0% 100%;
		--color-bg-tertiary-hover: 229 10% 88%;
		--color-bg-tertiary-selected: 229 10% 78%;
		--color-bg-info: 220 70% 30%;
		--color-bg-info-hover: 220 70% 35%;
		--color-bg-info-selected: 220 70% 20%;
		--color-bg-success: 137 70% 30%;
		--color-bg-success-hover: 137 70% 35%;
		--color-bg-success-selected: 137 70% 20%;
		--color-bg-warning: 29 70% 40%;
		--color-bg-warning-hover: 29 70% 45%;
		--color-bg-warning-selected: 29 70% 30%;
		--color-bg-critical: 346 70% 30%;
		--color-bg-critical-hover: 346 70% 35%;
		--color-bg-critical-selected: 346 70% 20%;

		--color-text: 229 7% 24%;
		--color-text-primary: 229 38% 22%;
		--color-text-inverse: 0 0% 100%;
		--color-text-muted: 229 7% 42%;
		--color-text-link: 229 38% 22%;
		--color-text-link-hover: 229 45% 42%;
		--color-text-brand: 313 100% 57%;

		--color-stroke: 42 19% 85%;
		--color-stroke-secondary: 42 19% 75%;
		--color-stroke-disabled: 42 8% 83%;
		--color-stroke-selected: 208 40% 75%;
		--color-stroke-input: 224 7% 70%;
		--color-stroke-input-hover: 224 7% 60%;
		--color-stroke-input-active: 224 7% 50%;
		--color-stroke-input-disabled: 224 7% 85%;
		--color-stroke-brand: 313 100% 65%;
		--color-stroke-info: 220 35% 70%;
		--color-stroke-success: 137 35% 70%;
		--color-stroke-warning: 29 35% 70%;
		--color-stroke-critical: 346 35% 70%;

		/* We need this to be transparent, not used anywhere else except focus ring */
		--color-focus-ring: 229, 100%, 22%, 0.35;

		--radius-sm: 0.25rem;
		--radius: 0.5rem;
		--radius-lg: 1rem;
		--radius-xl: 1.5rem;
		--radius-2xl: 2rem;
	}

	html {
		scroll-behavior: smooth;
	}

	body {
		@apply font-body text-foreground bg-background text-pretty tabular-nums leading-normal;
		line-height: 20px;
		font-feature-settings: "rlig" 1, "calt" 1;
	}

	* {
		@apply border-stroke;
	}

	h1,
	h2,
	h3,
	h4 {
		@apply font-title font-extrabold;
		@apply text-foreground-primary;
	}
}
